import React, { useState } from 'react';
import styles from '../styles/contactForm.module.css';
import { useTranslation } from 'react-i18next';

import facebookIcon from '../assets/icons/facebook.png';
import linkedinIcon from '../assets/icons/linkedin.png';
import githubIcon from '../assets/icons/github.png';
import phoneIcon from '../assets/icons/phone.png';

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hoveredIcon, setHoveredIcon] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    fetch("https://server-one-sand-33.vercel.app/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((result) => {
        console.log(result);
        setIsSubmitted(true);
        setErrorMessage('');
        setFormData({ name: '', email: '', message: '' });
        setTimeout(() => setIsSubmitted(false), 5000);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setErrorMessage('Error sending message. Please try again.');
        setTimeout(() => setErrorMessage(''), 5000);
      });
  };

  return (
    <section id="contact" className={styles.contactForm}>
      <h2>{t('contact')}</h2>

      {errorMessage && (
        <p className={styles.errorMessage}>{errorMessage}</p>
      )}

      <div className={styles.formContainer}>
        <div className={styles.formBackground}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder={t('namePlaceholder')}
              required
              className={styles.input}
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t('emailPlaceholder')}
              required
              className={styles.input}
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t('messagePlaceholder')}
              required
              className={styles.textarea}
            />

            <button type="submit" className={styles.submitButton} disabled={isSubmitted}>
              {isSubmitted ? t('messageSentSuccess') : t('submit')}
            </button>
          </form>
        </div>
      </div>

      <div className={styles.contactInfo}>
        <p>{t('contactMeVia')}</p>
        <div className={styles.icons}>
          <a href="https://www.facebook.com/tibor.kutik" target="_blank" rel="noreferrer" onMouseEnter={() => setHoveredIcon('facebook')} onMouseLeave={() => setHoveredIcon('')}>
            <img src={facebookIcon} alt="Facebook" className={styles.icon} />
          </a>
          <a href="https://www.linkedin.com/in/tibor-kutik-715b14160" target="_blank" rel="noreferrer" onMouseEnter={() => setHoveredIcon('linkedin')} onMouseLeave={() => setHoveredIcon('')}>
            <img src={linkedinIcon} alt="LinkedIn" className={styles.icon} />
          </a>
          <a href="https://github.com/tibco87" target="_blank" rel="noreferrer" onMouseEnter={() => setHoveredIcon('github')} onMouseLeave={() => setHoveredIcon('')}>
            <img src={githubIcon} alt="GitHub" className={styles.icon} />
          </a>
          <a href="tel:+421919487774" target="_blank" rel="noreferrer" onMouseEnter={() => setHoveredIcon('phone')} onMouseLeave={() => setHoveredIcon('')}>
            <img src={phoneIcon} alt="Phone" className={styles.icon} />
          </a>
        </div>

        <p className={`${styles.iconLabel} ${hoveredIcon ? styles.visible : ''}`}>
          {hoveredIcon ? t(hoveredIcon) : '\u00A0'}
        </p>
      </div>
    </section>
  );
};

export default ContactForm;
