import React from 'react';
import styles from '../styles/aboutMe.module.css';
import { useTranslation } from 'react-i18next';
import profileImage from '../assets/profile.jpg';

const AboutMe: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="aboutMe" className={styles.aboutMe}>
      <div className={styles.waveText}>
        <span>{t('aboutMeGreeting')} </span>
        <span className={styles.wave}>{t('aboutMeWavingHand')}</span>
      </div>
      
      <div className={styles.content}>
        <img src={profileImage} alt="Tibor Kútik" className={styles.photo} />

        {/* Statický text s pozadím v štýle VSCode a blikajúcim kurzorom */}
        <div className={styles.vscodeBackground}>
          <span className={styles.pTag}>&lt;p&gt;</span>
          <span className={styles.vscodeText}>{t('aboutMeTypedText')}</span>
          <span className={styles.pTag}>&lt;/p&gt;</span>
          <span className={styles.cursor}></span> {/* Blikajúci kurzor */}
        </div>

        <p className={styles.description}>
          {t('aboutMeDescription')}
        </p>
      </div>
    </section>
  );
};

export default AboutMe;
