import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      aboutMe: "About Me",
      aboutMeTitle: "About Me",
      aboutMeGreeting: "Hey!", // Preklad pre "Ahoj!"
      aboutMeWavingHand: "👋", // Emoji ruky
      aboutMeTypedText: "My name is Tibor Kútik and I'm a frontend web developer", // Upravený preklad bez <p> tagov
      aboutMeDescription: "I'm a self-taught software developer from Slovakia. My main focus is front-end web applications, but I also work with back-end. I really enjoy web application development, and I want to keep improving my coding skills.",
      mySkills: "My Skills",
      mySkillsTitle: "My Skills",
      myProjects: "My Projects",
      myProjectsTitle: "My Projects",
      showProjects: "Show Projects",
      hideProjects: "Hide Projects",
      contact: "Contact",
      namePlaceholder: "Your Name",
      emailPlaceholder: "Your Email",
      phonePlaceholder: "Your Phone number",
      messagePlaceholder: "Your Message",
      submit: "Send Message",
      contactMeVia: "Other contacts:",
      messageSentSuccess: "Your message has been sent successfully!",
      createdBy: "Created by",
      facebook: "Facebook",
      linkedin: "LinkedIn",
      github: "GitHub",
      phone: "Phone: +421 919 487 774",
      // Preklady pre názvy projektov
      project1Title: "Project 1",
      project2Title: "Project 2",
      project3Title: "Project 3",
      project4Title: "Project 4"
    }
  },
  sk: {
    translation: {
      aboutMe: "O mne",
      aboutMeTitle: "O mne",
      aboutMeGreeting: "Ahoj!", // Preklad pre "Ahoj!"
      aboutMeWavingHand: "👋", // Emoji ruky
      aboutMeTypedText: "Volám sa Tibor Kútik a som frontend web developer", // Upravený preklad bez <p> tagov
      aboutMeDescription: "Som samouk softvérový vývojár zo Slovenska. Moje hlavné zameranie sú frontend webové aplikácie, ale venujem sa aj backendu. Vývoj webových aplikácií ma veľmi baví a chcem sa v písaní kódu neustále zlepšovať.",
      mySkills: "Moje zručnosti",
      mySkillsTitle: "Moje Zručnosti",
      myProjects: "Moje Projekty",
      myProjectsTitle: "Moje Projekty",
      showProjects: "Zobraziť Projekty",
      hideProjects: "Skryť Projekty",
      contact: "Kontakt",
      namePlaceholder: "Vaše meno",
      emailPlaceholder: "Váš email",
      phonePlaceholder: "Vaše telefónne číslo",
      messagePlaceholder: "Vaša správa",
      submit: "Odoslať správu",
      contactMeVia: "Ďalšie kontakty:",
      messageSentSuccess: "Vaša správa bola úspešne odoslaná!",
      createdBy: "Vytvoril",
      facebook: "Facebook",
      linkedin: "LinkedIn",
      github: "GitHub",
      phone: "Telefón: +421 919 487 774",
      // Preklady pre názvy projektov
      project1Title: "Projekt 1",
      project2Title: "Projekt 2",
      project3Title: "Projekt 3",
      project4Title: "Projekt 4"
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Predvolený jazyk
  interpolation: {
    escapeValue: false, // React už escapuje hodnoty
  },
});

export default i18n;
