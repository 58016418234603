import React from 'react';
import styles from '../styles/myProjects.module.css';
import { useTranslation } from 'react-i18next';

const MyProjects: React.FC = () => {
  const { t } = useTranslation();

  const projects = [
    { id: 1, title: t('project1Title'), background: 'url(/path-to-project1-background.jpg)' },
    { id: 2, title: t('project2Title'), background: 'url(/path-to-project2-background.jpg)' },
    { id: 3, title: t('project3Title'), background: 'url(/path-to-project3-background.jpg)' },
    { id: 4, title: t('project4Title'), background: 'url(/path-to-project4-background.jpg)' }
  ];

  return (
    <section id="myProjects" className={styles.myProjects}>
      <h2>{t('myProjectsTitle')}</h2>
      <div className={styles.projectList}>
        {projects.map(project => (
          <div
            key={project.id}
            className={styles.projectCard}
            style={{ backgroundImage: project.background }}
            onClick={() => window.open(`https://example.com/project${project.id}`, '_blank')}
          >
            <span className={styles.projectTitle}>{project.title}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MyProjects;
